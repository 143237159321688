exports.components = {
  "component---src-templates-404-page-tsx": () => import("./../../../src/templates/404Page.tsx" /* webpackChunkName: "component---src-templates-404-page-tsx" */),
  "component---src-templates-about-page-tsx": () => import("./../../../src/templates/AboutPage.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-templates-careers-page-tsx": () => import("./../../../src/templates/CareersPage.tsx" /* webpackChunkName: "component---src-templates-careers-page-tsx" */),
  "component---src-templates-contact-page-tsx": () => import("./../../../src/templates/ContactPage.tsx" /* webpackChunkName: "component---src-templates-contact-page-tsx" */),
  "component---src-templates-csr-page-tsx": () => import("./../../../src/templates/CSRPage.tsx" /* webpackChunkName: "component---src-templates-csr-page-tsx" */),
  "component---src-templates-generic-page-tsx": () => import("./../../../src/templates/GenericPage.tsx" /* webpackChunkName: "component---src-templates-generic-page-tsx" */),
  "component---src-templates-history-page-tsx": () => import("./../../../src/templates/HistoryPage.tsx" /* webpackChunkName: "component---src-templates-history-page-tsx" */),
  "component---src-templates-home-page-tsx": () => import("./../../../src/templates/HomePage.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-ips-page-tsx": () => import("./../../../src/templates/IPSPage.tsx" /* webpackChunkName: "component---src-templates-ips-page-tsx" */),
  "component---src-templates-managing-page-tsx": () => import("./../../../src/templates/ManagingPage.tsx" /* webpackChunkName: "component---src-templates-managing-page-tsx" */),
  "component---src-templates-news-page-tsx": () => import("./../../../src/templates/NewsPage.tsx" /* webpackChunkName: "component---src-templates-news-page-tsx" */),
  "component---src-templates-offer-brakes-page-tsx": () => import("./../../../src/templates/OfferBrakesPage.tsx" /* webpackChunkName: "component---src-templates-offer-brakes-page-tsx" */),
  "component---src-templates-quality-page-tsx": () => import("./../../../src/templates/QualityPage.tsx" /* webpackChunkName: "component---src-templates-quality-page-tsx" */),
  "component---src-templates-values-page-tsx": () => import("./../../../src/templates/ValuesPage.tsx" /* webpackChunkName: "component---src-templates-values-page-tsx" */)
}

